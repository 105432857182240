<template>
  <div class="components d-flex flex-column pa-2">
    <v-card class="mb-3 pa-2 autocomplete">
      <v-autocomplete
        v-model="selectedComponent"
        :items="components"
        label="Components"
      ></v-autocomplete>
    </v-card>
    <component
      v-if="selectedComponent"
      :is="`${selectedComponent}-sample`"
    />

  </div>
</template>

<script>

export default {
  components: {
    // 'bar-value-tile': () => import('@/components/bar-value-tile'),
    // 'aging-buckets-tile': () => import('@/components/aging-buckets-tile'),
    // 'user-picker': () => import('@/components/user-picker'),
    // 'custom-fields': () => import('@/components/custom-fields'),
    // TextEditor: () => import('./text-editor'),
    // userAvatar: () => import('@/components/user-avatar'),
    // DragNDrop: () => import('./drag-n-drop'),
    // FileHandling: () => import('./file-handling'),
    ActivityEngineSample: () => import('./activity-engine-sample'),
    ApiAllocationDisputedInvoiceSample: () => import('./api-allocation-disputed-invoice-sample'),
    BarValueTileSample: () => import('./bar-value-tile-sample'),
    CalendarDateSample: () => import('./calendar-date-sample'),
    ColorPickerSample: () => import('./color-picker-sample'),
    ColumnPickerSample: () => import('./column-picker-sample'),
    ContentEditorSample: () => import('./content-editor-sample'),
    DisputeStatusSample: () => import('./dispute-status-sample'),
    DocumentRefListSample: () => import('./document-ref-list-sample'),
    FormsSample: () => import('./forms-sample'),
    IconMarkSample: () => import('./icon-mark-sample'),
    IconsServiceSample: () => import('./icons-service-sample'),
    ImprovedBtnToggleSample: () => import('./improved-btn-toggle-sample'),
    InvoiceDetailSample: () => import('./invoice-detail-sample'),
    LabelRefSample: () => import('./label-ref-sample'),
    LoadingAnimationSample: () => import('./loading-animation-sample'),
    LongTextFieldSample: () => import('./long-text-field-sample'),
    MainActionButtonInjectorSample: () => import('./main-action-button-injector-sample'),
    MainDocumentSample: () => import('./main-document-sample'),
    PickerSample: () => import('./picker-sample'),
    PopUpSelectionSample: () => import('./pop-up-selection-sample'),
    PromiseStatusSample: () => import('./promise-status-sample'),
    TemplateTextSample: () => import('./template-text-sample'),
    TranslationsSample: () => import('./translations-sample'),
    TranslationsViewerSample: () => import('./translations-viewer'),
    TypedDocPickerSample: () => import('./typed-doc-picker-sample'),
    UserAvatarSample: () => import('./user-avatar-sample'),
    UserChipSample: () => import('./user-chip-sample')
  },
  activated () {
    this.$store.commit('setModule', { name: 'Components' })
    this.selectedComponent = this.loadFromStorage()
  },
  computed: {
  },
  watch: {
    selectedComponent: function (v) {
      window.sessionStorage.selectedComponent = JSON.stringify(v)
    }
  },

  data () {
    return {
      // innerSelectedComponent: null,
      selectedComponent: null,
      components: [
        'activity-engine',
        'api-allocation-disputed-invoice',
        'bar-value-tile',
        'calendar-date',
        'color-picker',
        'column-picker',
        'content-editor',
        'dispute-collaboration-status',
        'document-ref-list',
        'forms',
        'icon-mark',
        'icons-service',
        'improved-btn-toggle',
        'invoice-detail',
        'label-ref',
        'loading-animation',
        'long-text-field',
        'main-action-button-injector',
        'main-document',
        'picker',
        'pop-up-selection',
        'promise-status',
        'template-text',
        'translations',
        'translations-viewer',
        'typed-doc-picker',
        'user-avatar',
        'user-chip'
      ]
    }
  },

  methods: {
    loadFromStorage () {
      const _ = JSON.parse(window.sessionStorage.selectedComponent || '"icons-service"')
      // var _ = this.$session.get('selectedComponent') ?? 'icons-service'
      return this.components.indexOf(_) > -1 ? _ : 'icons-service'
    }
  }
}
</script>

<style lang="stylus" scoped>
.components
  flex-grow 1
  overflow auto

.autocomplete
  width 400px
</style>
